import { globalDef } from '../site-definitions';

export class DonateHospital {
    public async fetchNearestHospitalByIP() {
        try {
            const timestamp = Date.now(); // Return the number of milliseconds since 1970/01/01
            const nearestHospitalFunction = `?ajaxFunctions&function=nearestHospital&t=${timestamp}`;
            const response = await fetch(globalDef.wordpressApiEndPoint + nearestHospitalFunction);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
            throw error;
        }
    }
}
